export const SliderData = [
    {
      id:1,
      image:
        'https://cdn.glitch.global/5288658f-8afb-4ac7-b1fa-300b8de8635f/My%20project%20(2).jpg?v=1653859032849'
    },
    {
      id:2,
      image:
      'https://cdn.glitch.global/5288658f-8afb-4ac7-b1fa-300b8de8635f/My%20project%20(12).png?v=1653859102787'    
    },
    
    {
      id:3,
      image:
        'https://cdn.glitch.global/5288658f-8afb-4ac7-b1fa-300b8de8635f/My%20project%20(13).png?v=1653859152371'
    },
    {
      id:4,
      image:
        'https://cdn.glitch.global/5288658f-8afb-4ac7-b1fa-300b8de8635f/My%20project%20(14).png?v=1653859196738'
    },
    {
      id:5,
      image:
        'https://cdn.glitch.global/5288658f-8afb-4ac7-b1fa-300b8de8635f/My%20project%20(15).png?v=1653859243824'
    }
  ];

 